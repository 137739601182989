import {
	Component, OnInit, ChangeDetectionStrategy, Inject, forwardRef, ViewChild, NgZone
}                                                                                                         from '@angular/core';
import { DashboardComponent }                                                                             from '../../dashboard.component';
import { DATA_CONSTANTS }                                                                                 from '@cs/core/generate';
import { take }                                                                                           from 'rxjs/operators';
import { DashboardGridData }                                                                              from '../../models';
import {
	ComponentLoaderDataContext,
	WizardContentComponent
}                                                                                                         from '@cs/components/shared';
import { WizardParentContext, WizardComponent, Base, WizardQuery, WizardService, WizardValidationResult } from '@cs/components/wizard';

@Component({
			   selector:        'cs-wizard-dashboard',
			   templateUrl:     './wizard-dashboard.component.html',
			   styleUrls:       ['./wizard-dashboard.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class WizardDashboardComponent extends WizardContentComponent<DashboardGridData>
	implements OnInit {

	static readonly TYPE_NAME = 'WizardDashboard';

	@ViewChild(DashboardComponent, {static: true}) dashboard: DashboardComponent;

	constructor(@Inject(ComponentLoaderDataContext)
				readonly context: ComponentLoaderDataContext<DashboardGridData, WizardParentContext>,
				@Inject(forwardRef(() => Base)) private host: WizardComponent,
				@Inject(forwardRef(() => WizardService)) wizardService: WizardService,
				@Inject(forwardRef(() => WizardQuery)) wizardQuery: WizardQuery,
				private _ngZone: NgZone) {
		super(context, wizardService, wizardQuery);

		this.context.data.panels.forEach(value => {
			if (this.host.formgroup.contains(value.name) && value.data == null) {
				const content = this.host.contentDataStore.get(value.name);
				// patching the datadescribed value
				const data    = this.host.formgroup.controls[value.name].value;
				content.data  = data.hasOwnProperty(DATA_CONSTANTS.UNPACK_ME_VAR)
								? data[DATA_CONSTANTS.UNPACK_ME_VAR]
								: data;
				value.data    = content;
			}
		});

		this.dataContext = context.data;

	}

	override ngOnInit(): void {
		super.ngOnInit();
	}

	validate(value: WizardValidationResult) {
		this._ngZone.onStable.pipe(take(1))
			.subscribe(value => {

				const messages = this.wizardStateQuery.getValue().errorMessages;

				for (const message of messages) {
					const foundPanel = this.dashboard.getPanelComponent(message.name);
					if (foundPanel) {
						// foundPanel.panel.note = message.errorMessage
						// 						? message.errorMessage
						// 						: message.validationResults.filter(validation => validation.type === 'invalid')
						// 								 .map(item => item.errorMessage)
						// 								 .join('\n');
						foundPanel.setError(message);
						foundPanel.detectChanges();
					}

				}
				this.dashboard.detectChanges();
			});


	}

}
