import { Inject, NgModule, Optional } from '@angular/core';
import { CommonModule }               from '@angular/common';
import { WizardFormNxtComponent }     from './wizard-form-nxt.component';
import { FormGeneratorNxtModule }     from '../../form-generator-nxt.module';
import { WizardComponentRegistry }    from '@cs/components/shared';


@NgModule({
						declarations: [],
						imports:      [
							CommonModule,
							FormGeneratorNxtModule
						]
					})
export class WizardFormNxtModule {
	constructor(@Optional() @Inject(WizardComponentRegistry) registry: WizardComponentRegistry) {

		if (registry) {
			registry.register(WizardFormNxtComponent.TYPE_NAME, WizardFormNxtComponent);
			registry.register('SimpleForm', WizardFormNxtComponent);
		}
	}
}
