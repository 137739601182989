import { Inject, NgModule, Optional } from '@angular/core';
import { CommonModule }               from '@angular/common';
import { WizardDashboardComponent }   from './wizard-dashboard.component';
import { WizardComponentRegistry }    from '@cs/components/shared';
import { DashboardModule }            from '../../dashboard.module';


@NgModule({
						declarations: [WizardDashboardComponent],
						exports:      [WizardDashboardComponent],
						imports:      [
							CommonModule,
							DashboardModule
						]
					})
export class WizardDashboardModule {
	constructor(@Optional() @Inject(WizardComponentRegistry) registry: WizardComponentRegistry) {

		if (registry) {
			registry.register(WizardDashboardComponent.TYPE_NAME, WizardDashboardComponent);
		}
	}
}
